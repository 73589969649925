<script lang="ts" setup>
import type { LinkParam, MovieInterface, Show } from '#gql/default'

interface Props {
  show: Show & {
    movie: MovieInterface & { hasInfos?: boolean; hasTrailers?: boolean }
  }
  linkParams?: LinkParam[]
}

defineProps<Props>()

const { t } = useI18n()
const isInfoModalVisible = ref(false)
const isTrailerModalVisible = ref(false)
const { icons } = useDesign()
defineOptions({
  name: 'ProgramShow',
})
</script>

<template>
  <div
    class="text-dark dark:text-light grid w-full grid-cols-[auto,1fr,auto] items-center gap-x-4 gap-y-2 py-3 md:grid-cols-[auto,1fr,auto,8rem]"
  >
    <div class="text-xl">
      <i18n-d
        tag="span"
        class="inline-block sm:block sm:text-3xl"
        :value="new Date(show.beginning)"
        :format="{
          minute: '2-digit',
          hour: '2-digit',
          timeZone: show.cinema?.city.timezone,
        }"
      />
    </div>

    <div class="flex flex-wrap items-center sm:col-span-1">
      <div class="mr-2" v-text="show.name" />
      <Badge v-for="{ name } in show.flags" :key="name" class="mr-1 text-xs">
        {{ name }}
      </Badge>
    </div>
    <div
      class="col-start-3 flex items-center justify-end space-x-1 sm:col-start-3"
    >
      <KButton
        v-if="show.movie.hasInfos"
        :theme="BUTTON_THEME.TERTIARY"
        :title="t('btn.info')"
        :icon="icons.INFO_OUTLINE"
        @click="isInfoModalVisible = true"
      />
      <KButton
        v-if="show.movie.hasTrailers"
        :theme="BUTTON_THEME.TERTIARY"
        :title="t('btn.playTrailer')"
        :icon="icons.PLAY_CIRCLE_OUTLINE"
        @click="isTrailerModalVisible = true"
      />
    </div>
    <div class="col-start-3 row-start-1 flex justify-end md:col-start-4">
      <ShowLink
        :show
        :link-params
        class="button button-primary button-base !h-10"
        :data-prefix="true"
      >
        <Icon :name="icons.TICKET_OUTLINE" class="size-6 fill-current" />
        <span v-text="t('btn.tickets')" />
      </ShowLink>
    </div>

    <LazyProgramMovieModal
      v-if="isInfoModalVisible"
      :uuid="show.showGroup?.uuid!"
      @close="isInfoModalVisible = false"
    />

    <LazyYoutubeModal
      v-if="isTrailerModalVisible"
      :remote-video-id="show.movie.trailers?.[0]?.remoteVideoId"
      @close="isTrailerModalVisible = false"
    />
  </div>
</template>

<i18n>
de:
  btn:
    playTrailer: "Trailer abspielen"
    info: "Infos"
    tickets: "Tickets"
es:
  btn:
    playTrailer: "Reproducir tráiler"
    info: "Información"
    tickets: "Entradas"
</i18n>
